import React, { useState } from 'react';
import './App.css';
import CharacterStudyPage from './CharacterStudy.js';
import notes from './BibleNotes.js'; // Import notes data
import barnabas from './bible_character_studies/barnabas.js'; // Import barnabas character data
import testCharacter from './bible_character_studies/test_character.js'; // Import testCharacter character data
import renderContent from './renderContent.js';
import homePageContent from './homePageContent.js';
import CommunityPage from './CommunityPage.js';
import MemoryGame from './MemoryGame';

function HomePage() {
  return (
    <div className="home-page">
      <h2>{renderContent(homePageContent.title)}</h2>
      <p>{renderContent(homePageContent.description)}</p>
      <p>{renderContent(homePageContent.note)}</p>
    </div>
  );
}

const scrollToLowerPage = () => {
  const element = document.getElementById('lower-page');
  element.scrollIntoView({ behavior: 'smooth' });
};

function App() {
  const [currentPage, setCurrentPage] = useState('home');
  const [isCharacterDropdownOpen, setIsCharacterDropdownOpen] = useState(false);
  const [isNotesDropdownOpen, setIsNotesDropdownOpen] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const [characters] = useState([barnabas, testCharacter]); // Updated characters array
  const [darkMode, setDarkMode] = useState(true); // State for dark mode
  const [notePassword, setNotePassword] = useState('');
  const [isPasswordPromptVisible, setIsPasswordPromptVisible] = useState(false);
  const [passwordProtectedNote, setPasswordProtectedNote] = useState(null);

  const toggleCharacterDropdown = () => {
    setIsCharacterDropdownOpen(!isCharacterDropdownOpen);
    if (!isCharacterDropdownOpen) {
      setIsNotesDropdownOpen(false);
    }
  };

  const toggleNotesDropdown = () => {
    setIsNotesDropdownOpen(!isNotesDropdownOpen);
    if (!isNotesDropdownOpen) {
      setIsCharacterDropdownOpen(false);
    }
  };

  const selectCharacter = (character) => {
    setSelectedCharacter(character);
    setCurrentPage('characterStudy');
    setIsCharacterDropdownOpen(false); // Close the character dropdown after selecting a character
    setTimeout(scrollToLowerPage, 20);
  };

  const handlePasswordSubmit = () => {
    if (passwordProtectedNote.password === notePassword) {
      setSelectedNote(passwordProtectedNote);
      setIsNotesDropdownOpen(false);
      setCurrentPage('noteContent');
      setTimeout(scrollToLowerPage, 20);
      setIsPasswordPromptVisible(false);
      setNotePassword('');
      setPasswordProtectedNote(null);
    } else {
      alert('Incorrect password!');
    }
  };

  const handlePasswordCancel = () => {
    setIsPasswordPromptVisible(false);
    setNotePassword('');
    setPasswordProtectedNote(null);
  };

  const selectNote = (note) => {
    if (note.password) {
      setPasswordProtectedNote(note);
      setIsPasswordPromptVisible(true);
      setIsNotesDropdownOpen(false);
    } else {
      setSelectedNote(note);
      setIsNotesDropdownOpen(false); // Close the notes dropdown after selecting a note
      setCurrentPage('noteContent');
      setTimeout(scrollToLowerPage, 20);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
      <header className="App-header">
        <h1>Saved Clay - Bible Notes</h1>
        <nav className="navigation">
          <button onClick={() => setCurrentPage('home')}>Home</button>
          <div className="dropdown">
            <button className="dropdown-button" onClick={toggleNotesDropdown}>
              Notes {isNotesDropdownOpen ? '▲' : '▼'}
            </button>
            <div className={`dropdown-content ${isNotesDropdownOpen ? 'show' : ''}`}>
              {notes.map((note) => (
                <div key={note.id} className="dropdown-item" onClick={() => selectNote(note)}>
                  {note.title}
                </div>
              ))}
            </div>
          </div>
          {/*<div className="dropdown">
            <button className="dropdown-button" onClick={toggleCharacterDropdown}>
              Character Studies {isCharacterDropdownOpen ? '▲' : '▼'}
            </button>
            <div className={`dropdown-content ${isCharacterDropdownOpen ? 'show' : ''}`}>
              {characters.map((character) => (
                <div key={character.id} className="dropdown-item" onClick={() => selectCharacter(character)}>
                  {character.name}
                </div>
              ))}
            </div>
          </div>*/}
          <button onClick={() => setCurrentPage('memoryGame')}>Memory Game</button>
          {/* Dark Mode Button */}
          <button className="dark-mode-toggle" onClick={toggleDarkMode}>{darkMode ? 'Light Mode' : 'Dark Mode'}</button>
        </nav>
      </header>
      <div className="container" id="lower-page">
        {currentPage === 'home' ? (
          <HomePage />
        ) : currentPage === 'community' ? (
          <CommunityPage />
        ) /*: currentPage === 'characterStudy' ? (
          <CharacterStudyPage character={selectedCharacter} />
        ) */: currentPage === 'noteContent' ? (
          <div className="note-content-page">
            <h2>{selectedNote.title}</h2>
            {selectedNote.title.includes("--!!--") ? (
              shuffleArray(selectedNote.sections).map((section) => (
                <NoteSection key={`${selectedNote.id}-${section.id}`} displaytitle={section.displaytitle} title={section.title} content={section.content} />
              ))
            ) : (
              selectedNote.sections.map((section) => (
                <NoteSection key={`${selectedNote.id}-${section.id}`} displaytitle={section.displaytitle} title={section.title} content={section.content} />
              ))
            )}
          </div>
        ) : currentPage === 'memoryGame' ? (
          <MemoryGame />
        ) : null}
      </div>

      {isPasswordPromptVisible && (
        <div className="password-prompt-overlay">
          <div className="password-prompt">
            <h2>Enter Password</h2>
            <input
              type="password"
              value={notePassword}
              onChange={(e) => setNotePassword(e.target.value)}
            />
            <button onClick={handlePasswordSubmit}>Submit</button>
            <button onClick={handlePasswordCancel}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

const NoteSection = ({ displaytitle, title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="note-section">
      {displaytitle && <h3 className="note-display-title">{displaytitle}</h3>}
      <div className="note-section-title" onClick={toggleSection}>
        {title}
      </div>
      {isOpen && (
        <div className="note-section-content active">
          {renderContent(content)}
        </div>
      )}
    </div>
  );
};

// Function to shuffle array
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default App;
