

const formatContent = (content) => {
    return content.split('\n').map(line => line.trim()).join('\n');
  };

const notes = [
    {
      id: 1,
      title: 'The Gospel',
      sections: [
        { id: 1, title: 'Depravity of Mankind', content: formatContent(`I will use this space here to explain to you (the reader), The Gospel
        of Jesus Christ to the best of my ability, using all the understanding
        that God might give me through his grace. Aiming to explain it here
        in the same manner I'd explain it to anyone, be them a taxi driver
        or a friend of mine at a party. And so in that fashion we start with
        my favorite sobering subject for any conversation: The total depravity
        of mankind.\m-b
        
    **Romans 3:10-12**
    *"as it is written:
    *“None is righteous, no, not one;
    *no one understands;
    *no one seeks for God.
    *All have turned aside; together they have become worthless;
    *no one does good,
    *not even one.”"\m-b

    In the book of Romans, we see that The Bible gives us a clear declaration regarding the state of all mankind. To put it bluntly:
    We are totally and completely deprived and have not even the slightest capability of being saved or even desiring to be saved.
    The reason for this, as we will later see echoed in **1 Peter 1:18-19**, is also given in Genesis chapter 3.\m-b
    
    **Genesis 3:17-19**
    *"And to Adam he said,
    *“Because you have listened to the voice of your wife
    *and have eaten of the tree
    *of which I commanded you,
    *‘You shall not eat of it,’
    *cursed is the ground because of you;
    *in pain you shall eat of it all the days of your life;
    *thorns and thistles it shall bring forth for you;
    *and you shall eat the plants of the field.
    *By the sweat of your face
    *you shall eat bread,
    *till you return to the ground,
    *for out of it you were taken;
    *for you are dust,
    *and to dust you shall return.”"\m-b
    
    When Adam, the creature made out of dust by the Holy, everlasting, righteous God,
    disobeys the very God who brought him into life and eats of the tree he commits a sin.
    This sin like any other sin, despite being trivialized and normalized in our culture today
    carries nothing less than the weight of death, and eternal damnation.
    **(Romans 6:23, 1 Corinthians 15:56, James 1:15, Revelation 20:14)**
    `)},
        { id: 3, title: 'The Seriousness of Sin', content: formatContent(`The seriousness of sin is why no one on earth is capable of reaching anywhere near close
        to the righteousness that God, because of his own Holiness and righteousness, requires, in order
        for us to reach heaven and dwell with him.\m-b

        God being righteous and just and Holy can't violate his attributes or do something that contradicts himself.
        And this is of course a good thing, because to live in a universe with an un-just God would be terrifying.
        But if God is just and righteous and Holy, how can he associate with us?\m-b

        **Isaiah 5:16**
        *"16 But the Lord of hosts is exalted in justice,
        *and the Holy God shows himself holy in righteousness."\m-b

        **Romans 3:23**
        *"for all have sinned and fall short of the glory of God."*\m-b

        
        To give an example of how serious this is: Even if hypothetically, (not considering the fact that we
        are all condemned to damnation because of the Original Sin of Adam), you were to live a life in which you
        only sinned once. Your whole life you were perfect, but you told one lie. You would still not meet the
        requirements for Heaven as God being Holy, righteous and just, cannot simply just let you off for your sins.
        He is an honorable, fair and righteous judge and can't just forgive your sin, because it would be against his
        very character. And just as there are no contradictions in the Bible, there are certainly no contradictions
        in God's character. As it says in scripture in the below verse:\m-b

        **1 Corinthians 6:9-11**
        *"9Or do you not know that the unrighteous will not inherit the kingdom of God?
        *Do not be deceived: neither the sexually immoral, nor idolaters, nor adulterers,
        *nor men who practice homosexuality, 10nor thieves, nor the greedy, nor drunkards,
        *nor revilers, nor swindlers will inherit the kingdom of God. 11And such were some
        *of you. But you were washed, you were sanctified, you were justified in the name
        *of the Lord Jesus Christ and by the Spirit of our God."\m-b

        The above passage also in it's last verse brings us
        nicely along to the next part of The Gospel, that being
        redemption through faith in Christ, alone.
        **(Ephesians 2:8-9)**
        `)},
        { id: 4, title: 'Redemption', content: formatContent(`Having left off at the sobering truth that no one is righteous enough to get to Heaven,
        I'm delighted to say that I now have first some more of that sobering news and then
        secondly, some incredibly good news to tell you.\m-b

        Having understood the serious nature of sin, we can be assured that 
        God in his Holy righteous and just nature at this point would be completely justified
        and not only that, completely righteous in sending us all to Hell.\m-b

        **Romans 3:23**
        *"for all have sinned and fall short of the glory of God."*\m-b

        **Nu.. 14:18**
        *"The Lord is slow to anger and abounding in steadfast love, forgiving iniquity and transgression, but he will by no means
        *clear the guilty, visiting the iniquity of the fathers on the children, to the third and the fourth generation."\m-b

        **Isaiah 5:16**
        *"But the Lord of hosts is exalted in justice, and the Holy God shows himself holy in righteousness."\m-b

        However God, while being a just God, is also a merciful God. So much so that over
        2000 years ago he came to walk the earth, in a physical body (John 1:14, 1 Peter 1:20), being born
        as Christ Jesus. He lived for 33 years, was tempted in every way (Hebrews 4:15), and
        yet did not sin even once (1 Peter 2:22). He was perfect, and was by far the only one
        who has ever been righteous enough to enter in to the kingdom of Heaven (Romans 8:3).\m-b

        But God did not just manifest himself into physical form and come to earth as
        Jesus Christ simply to live a sinless life and then ascend back up to heaven.
        At the age of 33 Jesus, after living a completely sinless life, was crucified
        on a cross. And in that act of dying on the cross he took every sin you have ever
        committed, including our Original Sin in Adam. And every sin you ever will commit.
        And bore them all on the cross, dying with them. And because of his righteousness,
        having never committed even one sin, death couldn't keep him. As the wages of sin
        is death (Romans 6:23), but Christ being free of any sin was also free of death, and so on
        the third day, he rose again, defeating death, and paying the fine for our sins (Romans 4:25),
        covering over all our sins, giving eternal life to all those who have faith in him.
        (2 Corinthians 5:21, 1 Peter 2:24, Isaiah 53:6)\m-b

        Simply put, if you die today, and have faith in Christ, when you are judged by the
        eternal, just, righteous, and merciful God on judgement day. He will look at your
        long list of sins, and instead of judging you by your own list of sins, of which
        even one sin would have you sent to Hell for eternity (Romans 3:23), he can get Jesus Christ's
        completely empty list of sins (1 Peter 2:22), and put it over the top of your list of sins. Covering
        all of the sins on the list to your name (Romans 3:24-25) with a purely empty spot-less list (1 Peter 1:18-19). That which
        Jesus Christ has obtained for you. It is because of this that God can look at us as
        blame-less, and as his sons, if we have faith in him. As we are counted righteous by not our own works,
        but by faith in Jesus Christ, alone.
        (Ephesians 2:4-9, 2 Corinthians 5:14-15)\m-b

        In other words. He payed the fine for our breaking of the law (Mark 10:45). Just like in a court of
        law on earth, when we die we will be judged by God and will need to pay somehow for our
        sins, a judge can't just let a criminal off for breaking a law because they want to. If
        they do that, they're not being truly just. There is a fine to pay. And in this case the
        fine is death, which results in Hell. For those who have faith in Jesus Christ, and
        subsequently have repented of their sin, Jesus has already payed the their fine, when he
        died on the cross (Romans 8:1-2). And so we can freely enter the kingdom of Heaven and dwell with God,
        being counted righteous, not because of our own works, but because of the sinless life,
        death, and resurrection of Jesus Christ (John 3:16).


        `)}
      ]
    },
    {
      id: 2,
      title: 'Bible Reliability',
      sections: [
        { id: 1, title: 'Reliability of The Bible', content: `
        It's widely accepted among scholars that **all of the books of the New Testament were written within a lifetime of the death of Jesus of Nazareth**. That is the books which we have in the bible today. The exact dating of each book is up to scholarly debate,
        but it's widely accepted that specifically **the gospels (Mathew, Mark, Luke and John) were written between 50AD to 100AD**.\m-b

        To date we have around **over 5800 Greek manuscripts** of the Bible's New Testament, with an astounding **2.6 million pages of biblical text**.\m-b

        While some of these manuscripts are small and fragmentary, the average size of a New Testament manuscript is 450 pages.\m-b
  
        So, when the original greek texts were written, take the New Testament gospels for example of Mathew, Mark, Luke, and John. These texts were carefully preserved and written down on materials like papyrus, a material made from the pith of the papyrus plant, or parchment (animal skin) for example.
        Meaning we can to this day actually reference the original Greek when reading the bible, in fact you can get apps on your phone to do such.\m-b

        While we do not have the originally written Greek text, there are over 5800 manuscripts or portions of manuscripts for the New Testament itself.
        That might sound like a lot or it might not sound like a lot to you so let me put it in perspective by comparing it to some other things.\m-b

        - For **Julius Caesar's Gallic Wars (which is how we know about Julius Caesar in his Conquest)**, we have around **10 manuscripts.**\m-b

        - For **Aristotle's Poetics**, we have **5 manuscripts.**\m-b

        - When it comes to the **writings of Herodotus**, we have less than **10 manuscripts.**\m-b

        - When it comes to the **writings of Homer**, we have less than **10 manuscripts** of each of his writings.\m-b

        - According to Corpus Coranicum, more than 60 fragments including more than 2000 folios (some 4000 pages) are so far known
        as the textual witnesses (manuscripts) of the Qur'an before 800 CE (within 168 years after the death of Muhammad).\m-b

        But we have over **5800 manuscripts** or portions of manuscripts for the Bible's **New Testament**.\m-b
  
        So yes, we don't have the originals but we can get as early as AD 100-150 with some of the copies that we have.\m-b
        
        Putting that in perspective again, when it comes to Julius Caesar's Gallic Wars, the earliest thing we can
        put our hands on was written **900 years** after the original.\m-b

        When it comes to Aristotle, the earliest thing we can put our hands on was written **1400 years** after the original.\m-b

        But when it comes to the New Testament, we can put our hands on documents that were written within mere **decades** of the original.\m-b

        You'd be hard pressed to find a scholar of this day who doesn't recognize at the very least the historical reliability of the Gospels.\m-b

        And you'd be even more hard pressed to find one that denies the life of Jesus of Nazareth. And that's despite the offensive nature that
        the modern world has towards Christianity. \m-b
        
        New Testament manuscripts source:
        {link:https://biblearchaeologyreport.com/2019/02/15/the-earliest-new-testament-manuscripts/}
        \m-b

        Islamic narratives sources:
        {link:https://en.wikipedia.org/wiki/History_of_the_Quran}
        {link:https://madainproject.com/historical_quranic_manuscripts}
        \m-b

        New Testament manuscript count:
        {link:https://seanmcdowell.org/blog/what-is-the-most-recent-manuscript-count-for-the-new-testament}
         \m-b

        Helpful PDF:
        {link:https://www.thecollegechurch.org/wp-content/uploads/2016/08/HANDOUTS-Is-Scripture-Reliable.pdf}
         \m-b
        
        New Testament reliability:
        {link:https://bible.org/seriespage/4-manuscripts-tell-story-new-testament-reliable}
         \m-b
        
        Voddie Baucham on this subject:
        {link:https://www.youtube.com/watch?v=nMfKlqMNnw0}
         \m-b
        
        Another Bible Manuscript source:
        {link:https://www.biblefactspress.com/bible-facts/new-testament-manuscripts/}
        ` },
        { id: 3, title: 'Manuscripts', content: `
        We have around **over 5800 Greek manuscripts** of the Bible's New Testament
        with **2.6 million pages of biblical text**.
        `},
        { id: 4, title: 'Manuscripts Perspective', content: `
        - For **Julius Caesar's Gallic Wars (which is how we know about Julius Caesar in his Conquest)**, we have around **10 manuscripts.**\m-b

        - For **Aristotle's Poetics**, we have **5 manuscripts.**\m-b

        - When it comes to the **writings of Herodotus**, we have less than **10 manuscripts.**\m-b

        - When it comes to the **writings of Homer**, we have less than **10 manuscripts** of each of his writings.\m-b
        
        - The **Quran** has **60 manuscripts.**\m-b

        But we have over **5800 manuscripts** or portions of manuscripts for the **New Testament**.\m-b`},
        { id: 5, title: 'Earliest Copy Date', content: `
        While yes, we don't have the originals (And **there are no originals of any classical text that has survived the ancient Greco-Roman world**).
        But **we can get as early as AD 100-150 with some of the copies that we have.**
        `},
        { id: 6, title: 'Earliest Copy Date Perspective', content: `
        - For Julius Caesar's Gallic Wars, the earliest thing we can
        put our hands on was written **900 years** after the original.\m-b

        - When it comes to Aristotle, the earliest thing we can put our hands on was written **1400 years** after the original.\m-b

        - But when it comes to the New Testament, we can put our hands on documents that were written within mere **decades** of the original.
        Having texts today, that were written as far back as A.D. 100-150.\m-b

        {link:https://biblearchaeologyreport.com/2019/02/15/the-earliest-new-testament-manuscripts/}
        `},
      ]
      },
    {
      id: 3,
      title: 'Necessity of the Law',
      sections: [
        { id: 1, title: 'Salvation by Grace, through Faith, found in Repentance (speaking phenomenologically), which is brought forth by the Law.', content: `
        **Romans 3:20**
        *"20For by works of the law no human being will be justified in his sight,
        *since **through the law comes knowledge of sin.***"\m-b

        In the words of Steven Lawson,
        *"If you have a broken ankle you want pain, because it tells you something's
        *wrong and you need to go have it set. The lack of pain is really a lack
        *of mercy. You would never know something is wrong."\m-b

        **Romans 3:21-24**
        *"21But now the righteousness of God has been manifested apart from the law, although the Law and the Prophets bear witness to it—
        *22the righteousness of God through faith in Jesus Christ for all who believe. For there is no distinction: 23for all have sinned
        *and fall short of the glory of God, 24and are justified by his grace as a gift, through the redemption that is in Christ Jesus,"\m-b
        
        **Romans 7:7**
        *"7What then shall we say? That the law is sin? By no means! Yet **if it had not been for the law, I would not have known sin.**
        *For I would not have known what it is to covet if the law had not said, “You shall not covet.”"\m-b
        
        **1 John 1:5-10**
        *"5This is the message we have heard from him and proclaim to you, that God is light, and in him is no darkness at all.
        *If we say we have fellowship with him while we walk in darkness, we lie and do not practice the truth. But if we walk in the light,
        *as he is in the light, we have fellowship with one another, and the blood of Jesus his Son cleanses us from all sin. If we say we
        *have no sin, we deceive ourselves, and the truth is not in us. If we confess our sins, he is faithful and just to forgive us our sins
        *and to cleanse us from all unrighteousness. If we say we have not sinned, we make him a liar, and his word is not in us.'"` },
      ]
      },
      {
        id: 4,
        title: 'House of God',
        sections: [
          { id: 1, title: 'Christian Assembly Being The House of God', content: `
          *Thanks to the book 'Why Johnny Can't Sing Hymns' by T. David Gordon*
          *for shedding light on this topic for me.*\m-b
          
          **1 Timothy 3:14-15**
          *"14I hope to come to you soon, but I am writing these things to you so that, 15if I delay,*
          *you may know how one ought to behave in the household of God, which is the church of the living God,*
          *a pillar and buttress of the truth."*\m-b

          **1 Peter 4:17**
          *"17For it is time for judgment to begin at the household of God; and if it begins with us,*
          *what will be the outcome for those who do not obey the gospel of God?"*\m-b

          **1 Corinthians 3:16-17**
          *"16 Do you not know that you are God’s temple and that God’s Spirit dwells in you?*
          *17If anyone destroys God’s temple, God will destroy him. For God’s temple is holy,*
          *and you are that temple."*\m-b

          **2 Corinthians 6:16**
          *"16What agreement has the temple of God with idols? For we are the temple of the living God; as God said,*
          *“I will make my dwelling among them and walk among them,*
          *and I will be their God,*
          *and they shall be my people."*
          
          ` },
          { id: 2, title: 'The House of God being Holy', content: `
          *Again thanks to the book 'Why Johnny Can't Sing Hymns' by T. David Gordon*
          *for shedding light on this topic for me.*\m-b
          
          **Genesis 28:16-17**
          *"16Then Jacob awoke from his sleep and said, “Surely the Lord is in this place, and I did not know it.”*
          *17And he was afraid and said, “How awesome is this place! This is none other than the house of God, and this is the gate of heaven.”"*\m-b

          As is brought to light in the aforementioned book when explaining why no discussion about what takes place in the house of God is insignificant *(Pages 27-29)*,
          the dwelling place of God with his people is a redemptive in nature.\m-b

          We should enter it in awe and reverence. In the Bible David was not permitted to build the house because of God's judgement against him.
          Which further shows us the intention of the Church being a place that exemplifies not only the care that should be taken when being in Church
          but also the idea of the house of God representing peace between God and man once again, communicating nothing less than the reversal of the curse-banishment of Genesis 3.\m-b

          I would add that when we enter the house of God we are in a sense entering "a new garden of Eden." Remembering of course that God is Holy, we should also remember that we
          are sinful and therefore should heed great care in the actions that we take regarding what we do with, or in the profounds of, the house of God.

          
          ` },
          // Add more sections as needed
        ]
      },
      {
        id: 10,
        title: 'General Verses',
        sections: [
          { id: 1, title: 'Ephesians 2:8-9', content: '8For by grace you have been saved through faith. And this is not your own doing; it is the gift of God, 9not a result of works, so that no one may boast.' },
          { id: 2, title: 'James 2:26', content: '26For as the body apart from the spirit is dead, so also faith apart from works is dead.' },
          { id: 3, title: 'Romans 3:10-11', content: `10as it is written:
          “None is righteous, no, not one;
          11no one understands;
          no one seeks for God.` },
          { id: 4, title: 'Romans 3:23-24', content: `23 for all have sinned and fall short of the glory of God, 24 and are justified by his grace as a gift, through the redemption that is in Christ Jesus,` },
          { id: 5, title: 'Numbers 14:18', content: '18‘The Lord is slow to anger and abounding in steadfast love, forgiving iniquity and transgression, but he will by no means clear the guilty, visiting the iniquity of the fathers on the children, to the third and the fourth generation.’' },
          { id: 6, title: 'Galatians 2:20', content: '20I have been crucified with Christ. It is no longer I who live, but Christ who lives in me. And the life I now live in the flesh I live by faith in the Son of God, who loved me and gave himself for me.' },
          { id: 7, title: '1 Corinthians 2:2', content: '2For I decided to know nothing among you except Jesus Christ and him crucified.' },
          { id: 8, title: 'Isaiah 5:16', content: '16But the Lord of hosts is exalted in justice, and the Holy God shows himself holy in righteousness.' },
          { id: 9, title: '1 Corinthians 6:9-11', content: '9Or do you not know that the unrighteous will not inherit the kingdom of God? Do not be deceived: neither the sexually immoral, nor idolaters, nor adulterers, nor men who practice homosexuality, 10nor thieves, nor the greedy, nor drunkards, nor revilers, nor swindlers will inherit the kingdom of God. 11And such were some of you. But you were washed, you were sanctified, you were justified in the name of the Lord Jesus Christ and by the Spirit of our God.' },
          { id: 10, title: '1 Timothy 2:3', content: '3This is good, and it is pleasing in the sight of God our Savior, 4who desires all people to be saved and to come to the knowledge of the truth.' },
          { id: 11, title: '2 Corinthians 4:7', content: `7But we have this treasure in jars of clay, to show that the surpassing power belongs to God and not to us.\m-b
          {clayPotImg}` },
          { id: 12, title: 'Isaiah 5:22', content: '22Woe to those who are heroes at drinking wine, and valiant men in mixing strong drink,' },
          { id: 13, title: 'John 14:12', content: '12“Truly, truly, I say to you,  whoever believes in me will also do the works that I do; and greater works than these will he do, because I  am going to the Father.' },
          { id: 14, title: '1 John 3:9-10', content: '9No one born of God makes a practice of sinning, for God’s seed abides in him; and he cannot keep on sinning, because he has been born of God. 10By this it is evident who are the children of God, and who are the children of the devil: whoever does not practice righteousness is not of God, nor is the one who does not love his brother.' },
          { id: 15, title: 'Mark 10:45', content: '45For even the Son of Man came not to be served but to serve, and to give his life as a ransom for many.”' },
          { id: 16, title: 'Ephesians 1:4-5', content: '4even as he chose us in him before the foundation of the world, that we should be holy and blameless before him. In love 5he predestined us for adoption to himself as sons through Jesus Christ, according to the purpose of his will,' },
          { id: 17, title: 'Philippians 1:6', content: '6And I am sure of this, that he who began a good work in you will bring it to completion at the day of Jesus Christ.' },
          { id: 18, title: 'Psalms 7:11', content: `11God is a righteous judge,
          and a God who feels indignation every day.` },
          { id: 19, title: '2 Timothy 3:16', content: '16All Scripture is breathed out by God and profitable for teaching, for reproof, for correction, and for training in righteousness,' },
          { id: 20, title: 'Matthew 23:12', content: `12Whoever exalts himself will be humbled, and whoever humbles himself will be exalted.` },
          { id: 21, title: 'John 14:15', content: '15“If you love me, you will  keep my commandments.' },
          { id: 22, title: '2 Corinthians 12:10', content: '10For the sake of Christ, then, I am content with weaknesses, insults, hardships, persecutions, and calamities. For when I am weak, then I am strong.' },
          { id: 23, title: '2 Corinthians 13:5', content: '5Examine yourselves, to see whether you are in the faith. Test yourselves. Or do you not realize this about yourselves, that Jesus Christ is in you?—unless indeed you fail to meet the test!'},
          { id: 24, title: 'Psalm 19:1', content: '1The heavens declare the glory of God, and the sky above proclaims his handiwork.'},
          { id: 25, title: 'Romans 1:18-20', content: '18For the wrath of God is revealed from heaven against all ungodliness and unrighteousness of men, who by their unrighteousness suppress the truth. 19For what can be known about God is plain to them, because God has shown it to them. 20For his invisible attributes, namely, his eternal power and divine nature, have been clearly perceived, ever since the creation of the world, in the things that have been made. So they are without excuse.'},
          { id: 26, title: 'Philippians 1:29', content: '29For it has been granted to you that for the sake of Christ you should not only believe in him but also suffer for his sake,'},
          { id: 27, title: 'Galatians 6:14', content: '14But far be it from me to boast except in the cross of our Lord Jesus Christ, by which the world has been crucified to me, and I to the world.'},
          
          { id: 28, title: 'Psalm 90:2', content: `2Before the mountains were brought forth,
          or ever you had formed the earth and the world,
          from everlasting to everlasting you are God.
          ` },
          { id: 29, title: 'Isaiah 45:7', content: `7I form light and create darkness;
          I make well-being and create calamity;
          I am the Lord, who does all these things.` },
          { id: 30, title: '2 Corinthians 5:17', content: '17Therefore, if anyone is in Christ, he is a new creation. The old has passed away; behold, the new has come.' },
          { id: 31, title: '1 Corinthians 1:18-19', content: `18For the word of the cross is folly to those who are perishing, but to us who are being saved it is the power of God. 19For it is written,
          “I will destroy the wisdom of the wise,
          and the discernment of the discerning I will thwart.”` },
          { id: 32, title: 'Proverbs 9:10', content: '10The fear of the Lord is the beginning of wisdom, and the knowledge of the Holy One is insight.' },
          { id: 33, title: 'John 8:58', content: '58Jesus said to them, “Truly, truly, I say to you, before Abraham was, I am.”' },
          { id: 34, title: 'John 14:9', content: '9Jesus said to him, “Have I been with you so long, and you still do not know me, Philip? Whoever has seen me has seen the Father. How can you say, ‘Show us the Father’?' },
          { id: 35, title: '1 Timothy 4:1', content: '1Now the Spirit expressly says that in later times some will depart from the faith by devoting themselves to deceitful spirits and teachings of demons,' },
          { id: 36, title: '2 Timothy 4:3-4', content: '3For the time is coming when people will not endure sound teaching, but having itching ears they will accumulate for themselves teachers to suit their own passions, 4and will turn away from listening to the truth and wander off into myths.' },
          { id: 37, title: '1 Corinthians 6:19-20', content: `19Or do you not know that your body is a temple of the Holy Spirit within you, whom you have from God? You are not your own, 20 for you were bought with a price. So glorify God in your body.` },
          { id: 38, title: 'Ephesians 1:7', content: `7In him we have redemption through his blood, the forgiveness of our trespasses, according to the riches of his grace,` },
          { id: 39, title: 'Ephesians 1:13-14', content: `13In him you also, when you heard the word of truth, the gospel of your salvation, and believed in him, were sealed with the promised Holy Spirit, 14 who is the guarantee of our inheritance until we acquire possession of it, to the praise of his glory.` },
          { id: 40, title: 'Psalms 34:18', content: `18The LORD is near to the brokenhearted
          and saves the crushed in spirit.` },
          { id: 41, title: 'Romans 2:15', content: '15They show that the work of the law is written on their hearts, while their conscience also bears witness, and their conflicting thoughts accuse or even excuse them' },
          
          { id: 42, title: 'Romans 13:7', content: `7Pay to all what is owed to them: taxes to whom taxes are owed, revenue to whom revenue is owed, respect to whom respect is owed, honor to whom honor is owed.` },
          { id: 43, title: 'James 2:19', content: `19 You believe that God is one; you do well. Even the demons believe—and shudder!` },
          { id: 44, title: 'James 4:3', content: `3You ask and do not receive, because you ask wrongly, to spend it on your passions.` },
          { id: 45, title: 'Habakkuk 1:13', content: `13You who are of purer eyes than to see evil
          and cannot look at wrong,
          why do you idly look at traitors
          and remain silent when the wicked swallows up
          the man more righteous than he?` },
          { id: 46, title: '2 Corinthians 5:21', content: `21 For our sake he made him to be sin who knew no sin, so that in him we might become the righteousness of God.` },
          { id: 47, title: 'Isaiah 30:18', content: `18 Therefore the LORD waits to be gracious to you,
          and therefore he exalts himself to show mercy to you.
          For the LORD is a God of justice;
          blessed are all those who wait for him.`},
          { id: 48, title: 'James 4:6', content: `*6 But he gives more grace. Therefore it says,* “God opposes the proud but gives grace to the humble.”`},
          { id: 49, title: 'Philippians 3:8', content: `8 Indeed, I count everything as loss because of the surpassing worth of knowing Christ Jesus my Lord. For his sake I have suffered the loss of all things and count them as rubbish, in order that I may gain Christ`},
          { id: 50, title: '1 Thessalonians 5:19', content: `19 Do not quench the Spirit.`},
        
        ]
      },
      {
        id: 11,
        title: 'Greek',
        sections: [
          { id: 1, title: 'α', content: 'Alpha' },
          { id: 2, title: 'β', content: 'Beta' },
          { id: 3, title: 'γ', content: 'Gamma' },
          { id: 4, title: 'δ', content: 'Delta' },
          { id: 5, title: 'ε', content: 'Epsilon' },
          { id: 6, title: 'ζ', content: 'Zeta' },
          { id: 7, title: 'η', content: 'Eta' },
          { id: 8, title: 'θ', content: 'Theta' },
          { id: 9, title: 'ι', content: 'Iota' },
          { id: 10, title: 'κ', content: 'Kappa' },
          { id: 11, title: 'λ', content: 'Lambda' },
          { id: 12, title: 'μ', content: 'Mu' },
          { id: 13, title: 'ν', content: 'Nu' },
          { id: 14, title: 'ξ', content: 'Xi' },
          { id: 15, title: 'ο', content: 'Omicron' },
          { id: 16, title: 'π', content: 'Pi' },
          { id: 17, title: 'ρ', content: 'Rho' },
          { id: 18, title: 'σ or ς', content: 'Sigma' },
          { id: 19, title: 'τ', content: 'Tau' },
          { id: 20, title: 'υ', content: 'Upsilon' },
          { id: 21, title: 'φ', content: 'Phi' },
          { id: 22, title: 'χ', content: 'Chi' },
          { id: 23, title: 'ψ', content: 'Psi' },
          { id: 24, title: 'ω', content: 'Omega' },
          
          
        ]
      },
      {
        id: 12,
        title: 'Jesus is God',
        sections: [
          { id: 1, title: 'John 8:58', content: '58Jesus said to them, “Truly, truly, I say to you, before Abraham was, I am.”' },
          { id: 2, title: 'John 14:9', content: '9Jesus said to him, “Have I been with you so long, and you still do not know me, Philip? Whoever has seen me has seen the Father. How can you say, ‘Show us the Father’?' },
          { id: 3, title: 'Titus 2:13', content: '13waiting for our blessed hope, the appearing of the glory of our great God and Savior Jesus Christ,' },
          { id: 4, title: '2 Peter 1:1', content: `"1Simeon Peter, a servant and apostle of Jesus Christ,
          To those who have obtained a faith of equal standing with ours by the righteousness of our God and Savior Jesus Christ:"` },
          { id: 5, title: 'Hebrews 1:8', content: `8But of the Son he says,
          “Your throne, O God, is forever and ever,
          the scepter of uprightness is the scepter of your kingdom.\m-b
          
          *Applying Psalm 45:6 to be about the Son, calling him God and also emphasizing the eternal nature of His reign*` },
          { id: 6, title: 'Romans 9:5', content: '5To them belong the patriarchs, and from their race, according to the flesh, is the Christ, who is God over all, blessed forever. Amen.' },
          { id: 7, title: '1 John 5:20', content: '20 And we know that the Son of God has come and has given us understanding, so that we may know him who is true; and we are in him who is true, in his Son Jesus Christ. He is the true God and eternal life.' },
         // Add more sections as needed
        ]
      },
      {
        id: 13,
        title: 'Repent',
        sections: [
          { id: 1, title: 'Repentance', content: 'Faith is the trusting in. Repentance is the turning from. Repentance is a catalyst for faith. Both faith and repentance come hand in hand and are inseparable.' },
          { id: 2, title: 'Luke 5:32', content: '32I have not come to call the righteous but sinners to repentance.”' },
          { id: 3, title: 'Mark 1:14-15', content: '14Now after John was arrested, Jesus came into Galilee, proclaiming the gospel of God, 15 and saying, “The time is fulfilled, and the kingdom of God is at hand; repent and believe in the gospel.”' },
          { id: 4, title: 'Matthew 3:8', content: '8Bear fruit in keeping with repentance.' },
          { id: 5, title: 'Matthew 4:17', content: '17From that time Jesus began to preach, saying, “Repent, for the kingdom of heaven is at hand.”' },
          { id: 6, title: 'Acts 3:19', content: '19Repent therefore, and turn back, that your sins may be blotted out,' },
          // Add more sections as needed
        ]
      },
      {
        id: 14,
        title: 'Worship',
        sections: [
          { id: 1, title: 'Ephesians 5:18-19', content: '18And do not get drunk with wine, for that is debauchery, but be filled with the Spirit, 19addressing one another in psalms and hymns and spiritual songs, singing and making melody to the Lord with your heart,' },
          { id: 2, title: 'Colossians 3:16', content: '16Let the word of Christ dwell in you richly, teaching and admonishing one another in all wisdom, singing psalms and hymns and spiritual songs, with thankfulness in your hearts to God.' },
        ]
      },
      {
        id: 15,
        title: 'Jonathan Edwards Resolutions',
        sections: [
          { id: 1, title: '70 Resolutions', content: `
          Jonathan Edwards: "Aware that I am unable to do anything without God’s help, I do pray that, by his grace, he will enable me to keep these Resolutions, so far as they are in line with his will, and that they will honor Christ."\m-b

1. Resolved:  I will DO whatever I think will be most to God’s glory; and my own good, profit and pleasure, for as long as I live. I will do all these things without any consideration of the time they take.  Resolved: to do whatever I understand to be my duty and will provide the most good and benefit to mankind in general. Resolved to do this, whatever difficulties I encounter, and no matter how many I experience or how severe they may be.\m-b

2. Resolved: I will continually endeavor to find new ways to practice and promote the things from Resolution 1.

3. Resolved: If ever – really, whenever – I fail & fall and/or grow weary & dull; whenever I begin to neglect the keeping of any part of these Resolutions; I will repent of everything I can remember that I have violated or neglected, …as soon as I come to my senses again.\m-b

4. Resolved: Never to do anything, whether physically or spiritually, except what glorifies God.  In fact, I resolve not only to this commitment, but I resolve not to even grieve and gripe about these things, …if I can avoid it.\m-b

5. Resolved: Never lose one moment of time; but seize the time to use it in the most profitable way I possibly can.\m-b

6. Resolved: To live with all my might, …while I do live.\m-b

7. Resolved: Never to do anything which I would be afraid to do if it were the last hour of my life.\m-b

8. Resolved: To act, in all respects, both in speaking and doing, as if nobody had ever been as sinful as I am; and when I encounter sin in others, I will feel (at least in my own mind& heart) as if I had committed the same sins, or had the same weaknesses or failings as others.  I will use the knowledge of their failings to promote nothing but humility – even shame – in myself. I will use awareness of their sinfulness and weakness only as an occasion to confess my own sins and misery to God.\m-b

9. Resolved: To think much, on all occasions, about my own dying, and of the common things which are involved with and surround death.\m-b

10. Resolved: When I feel pain, to think of the pains of martyrdom – both of Jesus and of Believers around the world; and remind myself of the reality of hell.\m-b

11. Resolved:  When I think of any theological question to be resolved, I will immediately do whatever I can to solve it, … if circumstances don’t hinder.\m-b

12. Resolved: If I find myself taking delight in any gratification of pride or vanity, or on any other such empty virtue, I will immediately discard this gratification.\m-b

13. Resolved: To be endeavoring to discover worthy objects of charity and liberality.\m-b

14. Resolved: Never to do anything out of revenge.\m-b

15. Resolved: Never to suffer the least emotions of anger about irrational beings.\m-b

16. Resolved: Never to speak evil of anyone, except if it is necessary for some real good.\m-b

17. Resolved: I will live in such a way, as I will wish I had done when I come to die.\m-b

18. Resolved: To live, at all times, in those ways I think are best in me during my most spiritual moments and seasons – those times when I have clearest understanding of the gospel and awareness of the World that is to come.\m-b

19. Resolved: Never to do anything, which I would be afraid to do if I expected it would not be more than an hour before I would hear the last trump sound.  (i.e. when Jesus returns.)\m-b

20. Resolved: To maintain the wisest and healthiest practices in my eating and drinking.\m-b

21. Resolved: Never to do anything, which if I saw another do, I would consider a just reason to despise him for, or to think in any way lesser of him.\m-b

22. Resolved: To endeavor to obtain for myself as much happiness in the world to come as I possibly can.  To accomplish this I will use all the strength, power, vigor, and vehemence – even violence – I am capable of, or can bring myself to exert, in any way that can be thought of.\m-b

23. Resolved: Frequently take some deliberate action – something out of the ordinary – and do it for the glory of God. Then I will trace my intention back and try to discern my real and deepest motive: What did I really desire out of it? If I find that my truest motive was not for God’s glory, then I consider it as a breach of the 4th Resolution. (See Above)\m-b

24. Resolved: Whenever I do any conspicuously evil action, I will trace it back till I come to the original cause; and then I will carefully endeavor BOTH 1) to do so no more AND 2) to fight and pray with all my might against the source of the original impulse.\m-b

25. Resolved: To examine carefully, and constantly, what that one thing in me is that causes me to doubt of the love of God, even the least little bit; and then to direct all my forces against it.\m-b

26. Resolved: To oust away anything I find that diminishes my assurance of God’s love and grace.\m-b

27. Resolved: Never intentionally omit or neglect anything, except if such an omission would be for the glory of God. NOTE to Self: frequently examine anything I have omitted.\m-b

28. Resolved: To study the Scriptures so steadily, and so constantly, and so frequently, that it becomes evident – even obvious – to myself that my knowledge of them has grown.\m-b

29. Resolved: Never consider something a prayer, nor to let pass for a prayer, any petition that when making I cannot actually hope that God will answer; nor offer as a confession anything which I cannot hope God will accept.\m-b

30. Resolved: To strive to my utmost every week to be brought to a higher spiritual place, and to a greater experience of grace, than I was the week before.\m-b

31. Resolved: Never to say anything at all against anybody; except when to do so is perfectly consistent with the highest standards of Christian honor and love to mankind; and except when it is consistent with the sense of greatest humility and awareness of my own faults and failings. Then, whenever I have said anything against anyone, I will examine my words against the strictest test of the Golden Rule.\m-b

32. Resolved: To be strictly and firmly faithful to whatever God entrusts to me.  My hope is that the saying in Proverbs 20.6,  “A faithful man who can find?” may not be found to be even partly true of me.\m-b

33. Resolved: Always do whatever I can towards making, maintaining, establishing and preserving peace, whenever it can be, but without over-balancing the value peace to such a degree that it becomes a detriment in other respects.\m-b

34. Resolved: When telling stories, never to speak anything but the pure and simple truth.\m-b

35. Resolved: Whenever I so much as question whether I have done my duty, to a point that my peace and tranquility is disturbed, I will stop and question myself until my concern is resolved.\m-b

36. Resolved: Never to speak evil of anyone, except I have some particular good purpose for doing so.\m-b

37. Resolved: To inquire every night, as I am going to bed, where I may have been negligent, what sin I have committed, and how I have denied myself. I will also do this at the end of every week, month, and year.\m-b

38. Resolved: Never to speak anything that is ridiculous, trivial, or otherwise inappropriate on the Lord’s Day or Sabbath evening.\m-b

39. Resolved: Never to do anything when the lawfulness is questionable. And then afterward, resolve to consider and examine whether or not whatever I have just done is truly lawful and/or whether whatever I have refrained from doing would have actually been permissible.\m-b

40. Resolved: To inquire every night, before I go to bed, whether I have acted in the best way I possibly could, with respect to eating and drinking.\m-b

41. Resolved: To ask myself at the end of every day, week, month and year, where I could have possibly done better in any respect.\m-b

42. Resolved: To frequently renew my dedication to God, which was first made at my baptism and which I solemnly renewed when I was received into the communion of the church; and which I have now solemnly re-made this [DATE] day of [MONTH], [YEAR].\m-b

43. Resolved: Never, from this day until the day I die, act as if I were in any way my own, but entirely and altogether belong to God, and then live in a way agreeable to this reality.\m-b

44. Resolved: That nothing other than the gospel shall have any influence at all on any of my actions; and that no action shall be, even in the very least circumstance, anything other than gospel declares, demands, and implies.\m-b

45. Resolved: Never to allow any pleasure or grief, joy or sorrow, nor any affection at all, nor any degree of affection, nor any circumstance, but what advances the gospel.\m-b

46. Resolved: Never allow the least measure of any fretting or uneasiness about my father or mother. Resolved to never allow the effects of disappointment in them, or frustrations with them, to even in the very least alter what I say to them or about them, or any activity in reaction to them.  Let me be careful about this, not only about my parents, but also with respect to any of our family.\m-b

47. Resolved: To endeavor to my utmost to deny whatever is not most agreeable to a good, and universally sweet and benevolent, quiet, peace able, contented, easy, compassionate, generous, humble, meek, modest, submissive, obliging, diligent and industrious, charitable, even, patient, moderate, forgiving, sincere temper; and to do at all times what such a temper would lead me to. Examine strictly every week, whether I have done so. Sabbath morning. May 5,1723.\m-b

48. Resolved: With the utmost niceness and diligence, and with the strictest scrutiny, constantly be looking into the state condition of my soul, so that I may know whether or not I have truly an interest in Christ at any given time. I will do this so that, when I come to my end in death, I will not have neglected to repent of anything I have found.\m-b

49. Resolved: That Neglect never shall be, if I can help it.\m-b

50. Resolved: I will act in such a way as I think I will judge to have been best and most prudent, when I have come into the future world – Heaven.\m-b

51. Resolved: That I will act in every respect, as I think I would wish I had done, if in the end for some reason I would have be damned.\m-b

52. I frequently hear persons in old age say how they would live, if they were to live their lives over again, so… Resolved: That I will live just as I can imagine I shall wish I had done, supposing I live to old age.\m-b

53. Resolved: To improve every opportunity, when I am in the best and happiest frame of mind, to cast and venture my soul on the Lord Jesus Christ, to trust and confide in him, and consecrate myself wholly to him; that from this I may have assurance of my eternal safety, knowing that my confidence is in my Redeemer.\m-b

54. Resolved: Whenever I hear anything spoken in a conversation of any person, if I think what is said of that person would be praiseworthy in me, I will endeavor to imitate it.\m-b

55. Resolved: To endeavor to my utmost to act as I can imagine I would if I had already seen all the happiness of heaven, as well as the torments of hell.\m-b

56. Resolved: Never to give up, nor even slacken up, in my fight with my own corruptions, no matter how successful or unsuccessful I may be.\m-b

57. Resolved: When I fear misfortunes and adversities, to examine whether I have done all I am expected to do, and resolve to do everything I am able to do.  Once I have done all that God requires of me, I will accept whatever comes my way, and accept that it is just as God’s Providence has ordered it.  I will, as far as I can, be concerned about nothing but my own duty and my own sin.\m-b

58. Resolved: Not only to refrain from an air of dislike, fretfulness, and anger in conversations, but also to exhibit an air of love, cheerfulness and graciousness.\m-b

59. Resolved: Whenever I am most conscious of feelings of ill nature, bad attitude, and/or anger, I will strive then the most to feel and act good naturedly.  At such times I know I may feel that to exhibit good nature might seem in some respects to be to my own immediate disadvantage, but I will nevertheless act in a way that is gracious, realizing that to do otherwise would be imprudent at other times (i.e. times when I am not feeling so irked).\m-b

60. Resolved: Whenever my feelings begin to appear in the least out of sorts, when I am conscious of the least uneasiness within my own heart and/or soul, or the least irregularity in my behavior, I will immediately subject myself to the strictest examination. (i.e. Psalm 42.11)\m-b

61. Resolved:  I will not give way to that apathy and listlessness which I find artificially eases and relaxes my mind from being fully and fixedly set on God’s Grace. Whatever excuses I may have for it, whatever my listlessness inclines me to do, or rather whatever it inclines me to neglect doing, I will realize that it would actually be best for me to do these things.\m-b

62. Resolved: Never to do anything but what God, by the Law of Love, requires me to do. And then, according to Ephesians 6.6-8, I must do it willingly and cheerfully as to the Lord, and not for man.  I must remember that whatever good thing any man has or does he has first received from God; and that whenever a man is compelled by faith to act with love and charity toward others, especially those in need, that we do it as if to/for the Lord.\m-b

63. On the hypothetical supposition that at any one time there was never to be but ONE individual in the world who was a genuine and complete Christian, who in all respects always demonstrated the Faith shining in its truest luster, and appearing excellent and lovely, from whatever angle and under whatever circumstance this Faith is viewed… Resolved: To act just as I would do, if I strove with all my strength, to be that ONE; and to live as if that ONE should live in my time and place.\m-b

64. Resolved: Whenever I experience those “groanings which cannot be uttered” (Romans 8.26), of which the Apostle speaks, and those “longings” that consume our souls, of which the Psalmist speaks (Psalm 119:20), I will embrace them with everything I have within me. And I will not be weary of earnestly endeavoring to express my desires, nor of the repetitions so often necessary to express them and benefit from them.\m-b

65. Resolved: To exercise myself in all my life long, with the greatest openness I am capable of, to declare my ways to God, and lay open my soul to him: all my sins, temptations, difficulties, sorrows, fears, hopes, desires; and every thing in every circumstance. (See Dr. Manton‘s 27th Sermon on Psalm 119.)\m-b

{link:https://archive.org/details/Sermon27OnPsalm119ByThomasMantonWhichJonathanEdwardsRecommendedIn} \m-b

66. Resolved:  I will endeavor always to keep a gracious demeanor, and air of acting and speaking in all places and in all companies, except if it should so happen that faithfulness requires otherwise.\m-b

67. Resolved: After afflictions, to inquire in what ways I am now the better for having experienced them. What good have I received by them? What benefits and insights do I now have because of them?\m-b

68. Resolved: To confess honestly to myself all that I find in myself – whether weakness or sin. And if it something that concerns my spiritual health, I will also confess the whole case to God, and implore him for all needed help.\m-b

69. Resolved: Always to do that which I will wish I had done whenever I see others do it.\m-b

70. Let there be something of benevolence, in all that I speak.\m-b
          
          ` },
         
        ]
      },
//       {
//         id: 16,
//         title: 'Q & A',
//         sections: [
//           { id: 1, title: 'Intro', content: `Below are questions that I have had in the past that I believe are note-worthy, as well as what I believe to be are the Biblical answers to them.`},
//           { id: 6, title: 'Where do dying babies go?', content: `
//           *Thanks to John MacArthur's book "Safe in the arms of God." Do give it a read as it is how I came to this conclusion.*\m-b

//           God knows and creates us before we are even conceived. He knows who we are from our mother's womb. And he knows the full destiny and purpose for each child. God has been intimately involved in every aspect of any baby's creation.
//           \m-b

//           **Psalm 139**
//           \m-b

// 1 O LORD, you have searched me and known me!
// 2 You know when I sit down and when I rise up;
// you discern my thoughts from afar.
// 3 You search out my path and my lying down
// and are acquainted with all my ways.
// 4 Even before a word is on my tongue,
// behold, O LORD, you know it altogether.
// 5 You hem me in, behind and before,
// and lay your hand upon me.
// 6 Such knowledge is too wonderful for me;
// it is high; I cannot attain it.
// 7 Where shall I go from your Spirit?
// Or where shall I flee from your presence?
// 8 If I ascend to heaven, you are there!
// If I make my bed in Sheol, you are there!
// 9 If I take the wings of the morning
// and dwell in the uttermost parts of the sea,
// 10 even there your hand shall lead me,
// and your right hand shall hold me.
// 11 If I say, “Surely the darkness shall cover me,
// and the light about me be night,”
// 12 even the darkness is not dark to you;
// the night is bright as the day,
// for darkness is as light with you.
// 13 For you formed my inward parts;
// you knitted me together in my mother’s womb.
// 14 I praise you, for I am fearfully and wonderfully made.
// Wonderful are your works;
// my soul knows it very well.
// 15 My frame was not hidden from you,
// when I was being made in secret,
// intricately woven in the depths of the earth.
// 16 Your eyes saw my unformed substance;
// in your book were written, every one of them,
// the days that were formed for me,
// when as yet there was none of them.
// 17 How precious to me are your thoughts, O God!
// How vast is the sum of them!
// 18 If I would count them, they are more than the sand.
// I awake, and I am still with you.
// 19 Oh that you would slay the wicked, O God!
// O men of blood, depart from me!
// 20 They speak against you with malicious intent;
// your enemies take your name in vain.
// 21 Do I not hate those who hate you, O LORD?
// And do I not loathe those who rise up against you?
// 22 I hate them with complete hatred;
// I count them my enemies.
// 23 Search me, O God, and know my heart!
// Try me and know my thoughts!
// 24 And see if there be any grievous way in me,
// and lead me in the way everlasting!\m-b

// **Psalm 22:9-10**\m-b

// 9 Yet you are he who took me from the womb;
// you made me trust you at my mother’s breasts.
// 10 On you was I cast from my birth,
// and from my mother’s womb you have been my God.\m-b

// God considers all babies to be His.\m-b
// God loves and cares for the meek, humble, and lowly. He "gives grace to the humble" (James 4:6). In the verse below he compares the Jews and God's grace on them to a baby's need for care and attention.\m-b


// **Ezekiel 16:4-7**
// 4 And as for your birth, on the day you were born your cord was not cut, nor were you washed with water to cleanse you, nor rubbed with salt, nor wrapped in swaddling cloths. 5 No eye pitied you, to do any of these things to you out of compassion for you, but you were cast out on the open field, for you were abhorred, on the day that you were born.
// 6 “And when I passed by you and saw you wallowing in your blood, I said to you in your blood, ‘Live!’ I said to you in your blood, ‘Live!’ 7 I made you flourish like a plant of the field. And you grew up and became tall and arrived at full adornment. Your breasts were formed, and your hair had grown; yet you were naked and bare.\m-b

// God in the verse below refers to babies as innocent ones. Implying that they, while are of-course were still born in sin (Ps. 51; Rom. 3:10-11; Rom. 3:23) and guilty, are included in the book of life as ones who are of the elect of God to recieve the innocence, righteousness, and freedom from sin that is achieved for them by Christ's work at calvary.\m-b

// **Jeremiah 19:4**
// 4 Because the people have forsaken me and have profaned this place by making offerings in it to other gods whom neither they nor their fathers nor the kings of Judah have known; and because they have filled this place with the blood of innocents,\m-b

// Due to babies not having developed the faculties and capabilities to consciously sin and/or repent or have faith. God declares them as innocent, not in the sense that they are without original sin and sin in general, but in the sense that they are not yet capable of sinning willfully with conscience. Sin is damning when comitted with with a conscience (2 Cor. 4:2; 1 Tim. 1:5).\m-b

// It could also perhaps also be said (and this is just speculation and clarification on my own behalf based on the scriptures I have looked into), that just as God accounted for the original sin commited in Adam, God has therefore also accounted for the original sin in which a baby is born in, and since a baby has no capability or conscience to willingly sin and/or repent the baby is declared innocent if below the age of accountability.\m-b

// **Who qualifies?**\m-b

// Firstly, even children who's parents are pagan and unbelieving, having committed the worst of sins or even satanic acts will go to Heaven instantly after dying.\m-b

// Every child who dies before reaching a condition of moral culpability goes instantly to Heaven after dying.\m-b

// At the age of twelve Jesus said to His mother he must be about His Father's house. This was him acknowledging His moral responsibility and the calling on His life for the first time. This is a good age marker to go by, however it is not age, but condition and ability to understand the gospel and sin, as some children may never be able to understand no matter how old they get, having been born with disabilities that block them from doing so. In this case they also, having the mental capabilities of a child despite their age will go to Heaven instantly upon dying.\m-b

// **Luke 2:49**
// 49 And he said to them, “Why were you looking for me? Did you not know that I must be in my Father’s house?”



//           `},
//         ]
//         },
      // {
      //   id: 6,
      //   title: 'Assurance Verses',
      //   sections: [
      //     { id: 1, title: 'Ephesians 2:8-9', content: '8For by grace you have been saved through faith. And this is not your own doing; it is the gift of God, 9not a result of works, so that no one may boast.' },
      //     { id: 2, title: 'James 2:26', content: '26For as the body apart from the spirit is dead, so also faith apart from works is dead.' },
      //     { id: 3, title: '2 Corinthians 13:5', content: `5Examine yourselves, to see whether you are in the faith. Test yourselves. Or do you not realize this about yourselves, that Jesus Christ is in you?—unless indeed you fail to meet the test!` },
      //   ]
      // },
    // {
    //   id: 5,
    //   title: 'False Converts',
    //   sections: [
    //     { id: 1, title: 'Dealing with a false convert', content: `
    //     As we know, there are many false converts in our time, and
    //     the bible tells us that there will continue to be more.\mb

    //     **2 Timothy 4:3-4**
    //     *3For the time is coming when people will not endure sound teaching, but having itching ears they will accumulate for themselves teachers to suit their own passions,
    //     *4and will turn away from listening to the truth and wander off into myths.\mb

    //     In light of this, I'd like to first explain why it's always extremely important to know how to handle a
    //     situation in which you find yourself speaking with someone, potentially in depth, who you believe to be
    //     a false convert. It's important to note that while scripture does tell us to examine our own
    //     salvation (2 Corinthians 13:5), we would be wise not to assume that we can know the state of salvation
    //     that our peers are in. Only God knows the condition of someones heart and we cannot tell from outward appearances.
    //     However, that being said, scripture also gives us much information regarding how someone who is saved will,
    //     or should think and act, both not only outwardly, but inwardly also. For example these verses from 1 John:\mb

    //     **1 John 2:4-6**
    //     *4Whoever says “I know him” but does not keep his commandments is a liar, and the truth is not in him, 5but whoever keeps his word, in him truly the love of God is perfected. By this we may know that we are in him: 6whoever says he abides in him ought to walk in the same way in which he walked.\mb

    //     **1 John 3:9-10**
    //     *9No one born of God makes a practice of sinning, for God’s seed abides in him; and he cannot keep on sinning, because he has been born of God. 10By this it is evident who are the children of God, and who are the children of the devil: whoever does not practice righteousness is not of God, nor is the one who does not love his brother.\mb
        
    //     John also talks about how we should always have a love for fellow believers in Christ:\mb
        
    //     **1 John 2:9-10**
    //     *9Whoever says he is in the light and hates his brother is still in darkness.
    //     *10Whoever loves his brother abides in the light, and in him there is no cause for stumbling.\mb

    //     Knowing from Paul (Corinthians), that the nature of love is always trusting, we can gather
    //     therefore from these above verses that to approach someone who claims to be in Christ, in a fashion
    //     which assumes from your perspective that they are lying, for example, would actually be acting in
    //     an un-trusting and therefore unloving manner. So in this sense, rebuking someone who claims to be in
    //     Christ (without basing your argument on the scriptures), and only basing your argument on your own
    //     assumptions, actually puts you at risk of being your self in the category of "false convert," based
    //     on what John is saying here in this verse above as well as what Paul has layed down for us about the
    //     nature of love itself.\mb

    //     This of course is all under the condition that your assumption about someone's unbelief is not backed
    //     up with any scripture, which would indicate that this person is not a believer, when comparing them to
    //     how scripture describes a believer.
    //     ` },
    //   ]
    // },
    // {
    //   id: 6,
    //   title: 'Drugs & Alcohol',
    //   sections: [
    //     { id: 1, title: 'Coming soon...', content: `
    //     Coming soon...
    //     ` },
    //   ]
    // },
  ];
  
  export default notes;