import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios
const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';

function CommunityPage() {
    const [posts, setPosts] = useState([]);
    const [newPostTitle, setNewPostTitle] = useState('');
    const [newPostContent, setNewPostContent] = useState('');
    console.log(process.env.REACT_APP_SERVER_URL);
    useEffect(() => {
        // Fetch initial posts from the server when component mounts
        axios.get(`${serverUrl}/api/posts`)
        .then(response => {
            setPosts(response.data);
        })
        .catch(error => {
            console.error('Error fetching posts:', error.message, error.response);
        });
}, []);
    
    const handleTitleChange = (e) => {
        setNewPostTitle(e.target.value);
    };

    const handleContentChange = (e) => {
        setNewPostContent(e.target.value);
    };

    const handlePostSubmit = () => {
        // Check if the new post title and content contain at least 1 character
        if (!newPostTitle.trim() || !newPostContent.trim()) {
            console.error('Post title or content cannot be empty.');
            return;
        }
        
        console.log("Post submitted.")
        // Make a POST request to create a new post
        axios.post(`${serverUrl}/api/posts`, { title: newPostTitle, content: newPostContent })
            .then(response => {
                console.log(response.data); 
                // Update state with the new post
                setPosts([...posts, response.data]);
                // Clear the input fields after submitting
                setNewPostTitle('');
                setNewPostContent('');
            })
            .catch(error => {
                console.error('Error creating post:', error);
            });
    };

    const handleCommentSubmit = (postId, commentContent) => {
        // Check if the new post title and content contain at least 1 character
   if (!commentContent || !commentContent.trim()) {
        console.error('Comment content cannot be empty.');
        return;
    }
        // Make a POST request to add a comment to a post
        axios.post(`${serverUrl}/api/posts/${postId}/comments`, { content: commentContent })
            .then(response => {
                console.log(response.data); 
                // Find the post and update its comments
                const updatedPosts = posts.map(post => {
                    if (post._id === postId) {
                        return {
                            ...post,
                            comments: [...post.comments, response.data]
                        };
                    }
                    return post;
                });
                // Update state with the updated posts
                setPosts(updatedPosts);
            })
            .catch(error => {
                console.error('Error adding comment:', error);
            });
    };

    const handleShowComments = (postId) => {
        // Toggle the show comments state for the specific post
        const updatedPosts = posts.map(post => {
            if (post._id === postId) {
                return {
                    ...post,
                    showComments: !post.showComments
                };
            }
            return post;
        });
        setPosts(updatedPosts);
    };

    const handleDeletePost = (postId) => {
        // Make a DELETE request to delete the post
        axios.delete(`${serverUrl}/api/posts/${postId}`)
            .then(response => {
                console.log(response.data); 
                // Filter out the deleted post from the posts array
                const updatedPosts = posts.filter(post => post._id !== postId);
                // Update state with the updated posts
                setPosts(updatedPosts);
            })
            .catch(error => {
                console.error('Error deleting post:', error);
            });
    };

    return (
        <div className="community-page">
            <h2>Community Forum (Work in progress...)</h2>
            <div className="post-form">
                <input
                    type="text"
                    placeholder="Enter post title"
                    value={newPostTitle}
                    onChange={handleTitleChange}
                />
                <textarea
                    placeholder="Enter post content: Use **Bold** to make text bold, and *italic* to make text italicized."
                    value={newPostContent}
                    onChange={handleContentChange}
                ></textarea>
                <button onClick={handlePostSubmit}>Submit Post</button>
            </div>
            <div className="posts">
                {posts.map((post) => (
                    <div key={post._id} className="post">
                        <button className="delete-button" onClick={() => handleDeletePost(post._id)}>x</button>
                        <h3>{post.title}</h3>
                        <p>{post.content}</p>
                        <p id="post-date">Date: {post.date ? new Date(post.date).toLocaleString() : ''}</p> {/* Display date if exists */}
                        <button id="show-hide-comments" onClick={() => handleShowComments(post._id)}>
                            {post.showComments ? "Hide Comments" : "Show Comments"}
                        </button>
                        {post.showComments && (
                            <div className="comments">
                                {post.comments.map((comment, index) => (
            <div key={index} className="comment">
                <p>{comment}</p>
            </div>
        ))}
                                <div className="comment-form">
                                    <textarea
                                        placeholder="Add your comment..."
                                        value={post.newCommentContent || ""}
                                        onChange={(e) => {
                                            const updatedPosts = posts.map(p => {
                                                if (p._id === post._id) {
                                                    return {
                                                        ...p,
                                                        newCommentContent: e.target.value
                                                    };
                                                }
                                                return p;
                                            });
                                            setPosts(updatedPosts);
                                        }}
                                    ></textarea>
                                    <button onClick={() => handleCommentSubmit(post._id, post.newCommentContent)}>
                                        Submit Comment
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CommunityPage;
